.center {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn2 {
    margin-left: 1rem;
  }
  .dateRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .dateRow > div{
    width: 30% !important;
  }
  .timeFrom{
    margin-left: 1rem
  }
  .modal{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .modalFirst{
    margin-bottom: 1rem;
  }
  .modalFirstEditing{
    width: 100%;
    
  }
  .addPoint{
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .saveBtn{
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
 