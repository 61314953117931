.btnSubmit {
  margin-bottom: 16;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  float: right;
}
.btnCancel {
  padding-top: 1rem;
}
.delete{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}