.header{
  background-color: #013C74 !important;
  display: flex;
}

.wrapper {
  width: 33%;
}

.home {
  width: 150px;
  float: left;
  margin-right: -6rem;
}

.logo {
  width: 120px;
  float: left;
  margin-top: 0.5rem;
}

.back {
  width: 150px;
  float: left;
}
.center {
	width: 350px;
	color: white;
	float: center;
	margin-right: -2rem;
}

.userInformation {
  color: white;
  float: right;
  margin-right: 0.5rem;
  text-align: right;
}

.logout {
	float: right;
	margin-right: 0.5rem;
}

.question {
    float: right;
    margin-right: 0rem;
}
  
.portal {
    justify-content: "center";
    width: 100%;
    float: left;
    color: white;
    font-size: 24pt;
    font-family: Arial;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: -40px;
  margin-right: 15px;
  display: none;
}

@media (max-width: 1200px) {
  .header {
    padding: 0 1rem 0 1rem !important;
  }
}

