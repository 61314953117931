.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #013C74 !important;
}

@media screen and (max-width: 725px){
  .container {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
    color: #013C74 !important;
  }
}

.wrapperImpressum {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.wrapperImpressum > * {
  margin-right: 1rem;
}

.wrapperIcons {
  width: 100%;
}

.wrapperIcons > * {
  color: #013C74 !important;
  margin: .5rem;
  font-Size: 1.5rem;
}

.wrapperOGE {
  width: 100%;
}

.link {
  color: #013C74 !important;
}