.container {
  height: 100%;
}
.card {
    width: 75%;
    height: 65%;
    left: 15%;
    cursor: pointer;
    border-radius: 5%;
  }
.card:hover{
  box-shadow: 0.1rem 0.1rem 0.1rem grey;
}
.gutter-row{ 
    padding-top: 1%;
}